<template>
  <div>
    <div v-if="!$data" v-cloak class="loading-container">
      Please wait...
      <loading-animation/>
    </div>
    <v-app id="inspire" dark class="application">
      <template v-if="isMounted">
        <the-app-drawer ref="drawer"
                        v-if="!isIOSDevice"
                        :show-instrument-change-button="areAllModelsLoaded"
                        @show-instrument-change-dialog="instrumentsDialog = true"
                        @show-settings-dialog="settings = true"/>
        <the-app-drawer-mobile ref="drawer"
                               v-else/>
        <the-options-drawer ref="options"
                            v-if="$vuetify.breakpoint.xsOnly"
                            :show-instrument-change-button="areAllModelsLoaded"
                            @show-instrument-change-dialog="instrumentsDialog = true"
                            @show-settings-dialog="settings = true"/>
        <the-app-bar @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
                     @open-logout-dialog="logout"
                     @open-help-dialog="help = true"/>
      </template>
      <v-main v-if="!getIsLoadingPage" :class="`application ${$vuetify.breakpoint.mdAndDown ? '' : 'no-select'}`">
        <v-container fluid class="text-center transparent">
          <transition name="slide-fade">
            <keep-alive :include="keepAliveRoutes">
              <router-view/>
            </keep-alive>
          </transition>
          <v-dialog v-model="settings" max-width="500">
            <the-settings-dialog @close="settings=false"/>
          </v-dialog>
          <v-dialog v-model="help"
                    max-width="400">
            <the-help-dialog @close="help=false"/>
          </v-dialog>
          <v-dialog v-if="areAllModelsLoaded" fullscreen v-model="instrumentsDialog"
                    overlay-opacity=".9">
            <select-instrument full-screen @close="instrumentsDialog=!instrumentsDialog" :player="getMainPlayerID"/>
          </v-dialog>
          <v-dialog v-model="logoutDialog" max-width="300">
            <logout @close="logoutDialog=false"/>
          </v-dialog>
          <the-snackbar/>
          <the-main-dialog/>
          <v-snackbar v-model="isDownloadingToastr">
            Downloading Audio...
            <v-progress-circular
              indeterminate
              color="secondary"/>
          </v-snackbar>
        </v-container>
      </v-main>
      <div v-else class="loading-container">
        Please wait...
        <loading-animation/>
      </div>
      <the-app-footer-mobile v-if="$vuetify.breakpoint.xsOnly && isMounted && isIOSDevice"
                             @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
                             @toggle-options-drawer="$refs.options.drawer = !$refs.options.drawer"/>
      <!--    <the-app-footer v-else/>-->
    </v-app>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import appInstanceConfig          from 'framework/environment/platform'

export default {
  components: {
    LoadingAnimation:   () => import('framework/ui/components/LoadingAnimation'),
    // TheAppFooter:       () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheAppFooter'),
    TheAppFooterMobile: () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheAppFooterMobile'),
    TheAppBar:          () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheAppBar'),
    TheAppDrawer:       () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheAppDrawer'),
    TheAppDrawerMobile: () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheAppDrawerMobile'),
    TheOptionsDrawer:   () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheOptionsDrawer'),
    TheSettingsDialog:  () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheSettingsDialog'),
    TheHelpDialog:      () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheHelpDialog'),
    Logout:             () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/Logout'),
    SelectInstrument:   () => import(/* webpackChunkName: "select-instrument" */ 'framework/ui/sections/SelectInstrument'),
    TheSnackbar:        () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheSnackbar'),
    TheMainDialog:      () => import(/* webpackChunkName: "app-main" */ 'framework/ui/sections/TheMainDialog')
  },

  mounted() {
    requestAnimationFrame(() => {
      this.isMounted = true
    })
  },

  data() {
    return {
      isMounted:         false,
      initialized:       false,
      settings:          false,
      help:              false,
      logoutDialog:      false,
      iphoneNotice:      false,
      instrumentsDialog: false,
    }
  },

  computed: {
    ...mapGetters('environment', ['getIsRefreshingApp']),
    ...mapGetters('user', ['getUser']),
    ...mapGetters('environment', ['newContentAvailable']),
    ...mapGetters('view', ['getIsDownloading', 'getIsLoadingPage']),
    ...mapGetters('players', ['getMainPlayerID']),

    isDownloadingToastr: function() {
      return this.getIsDownloading
    },

    areAllModelsLoaded() {
      return !!this.$store.state.transport
    },

    isIOSDevice: function() {
      return appInstanceConfig.isIOS
    },

    keepAliveRoutes: function() {
      if (process.env.NODE_ENV === 'production') {
        return ['home']
      }
      return ['home']
    }
  },

  methods: {
    ...mapActions('view', ['setSnackbar']),

    logout() {
      if(this.getUser) {
        this.logoutDialog = true
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../node_modules/typeface-roboto/index.css';

[v-cloak] > * {
  display: none;
}

[v-cloak]::before {
  content: " ";
  display: block;
}

html {
  height: 100%;
}

body {
  margin: 0;

  a {
    font-weight: 500;
    text-decoration: none;
  }

  #app {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.no-select {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

.border {
  border: solid 1px;
}

.slide-fade-enter-active {
  transition: all .3s ease-in-out;
}

.slide-fade-enter {
  transform: translateX(250px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.loading-container {
  text-align: center;
  margin-top: 30vh;
  font-size: 30px;
}

.application {
  //background-color: var(--theme-top);
  background-image: var(--background);
}
</style>

import state     from 'src/store/exercises/exercises.state'
import getters   from 'src/store/exercises/exercises.getters'
import mutations from 'src/store/exercises/exercises.mutations'
import actions   from 'src/store/exercises/exercises.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default {
  setIsDownloading({ commit }, value) {
    commit('SET_IS_DOWNLOADING', value)
  },

  setSnackbar({ commit }, payload) {
    commit('SET_SNACKBAR', payload)
  },

  setDialog({ commit }, payload) {
    commit('SET_DIALOG', payload)
  },

  setIsLoadingPage({ commit }, payload) {
    commit('SET_IS_LOADING_PAGE', payload)
  },
}

import state     from './settings.state'
import getters   from './settings.getters'
import mutations from './settings.mutations'
import actions   from './settings.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

import state     from 'framework/store/view/view.state'
import mutations from 'framework/store/view/view.mutations'
import actions   from 'framework/store/view/view.actions'
import getters   from 'framework/store/view/view.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import Vue      from 'vue'
import Vuetify  from 'vuetify/lib'
import appTheme from 'app-config/theme'
import values   from 'framework/ui/icon-system/vuetify-custom'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values,
  },
  theme: {
    themes: {
      dark: appTheme,
    },
    dark:   true,
  },
})

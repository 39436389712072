import Vue  from 'vue'
import Vuex from 'vuex'

import environment from 'framework/store/environment'
import view        from 'framework/store/view'
import settings    from './settings'
import user        from './user'
import exercises   from './exercises'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:   {
    environment,
    settings,
    user,
    view,
    exercises
  },
  mutations: {
    // For saving the users state
    LOAD_MODULE(state, { moduleName, moduleState }) {
      Object.assign(state[moduleName], moduleState)
    }
  },
  strict:    process.env.NODE_ENV === 'development'
})

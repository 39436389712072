// import Bowser from 'bowser'

export const isTWA        = document.referrer.includes('android-app://com.scales.scaleheaven')
export const isIOS        = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
export const isStandAlone = window.matchMedia('(display-mode: standalone)').matches

// export const isTrue      = x => x === true
// /**
//  * Detects Safari browser from user-agent
//  * @param {String} UA
//  */
// export const isSafari    = (UA) => Bowser.getParser(UA).satisfies({ safari: '>0' })
// /**
//  * True for Safari versions older than 11 (Sep 2017); false otherwise
//  * @param {string} UA user agent
//  */
// export const isOldSafari = (UA) => isTrue(Bowser.getParser(UA).satisfies({ safari: '<11' }))
//
// export const isFireFox      = (UA) => isTrue(Bowser.getParser(UA).satisfies({ firefox: '>0' }))
// export const isWebkit       = (UA) => Bowser.getParser(UA).getEngine().name === 'WebKit'
// export const isAppleProduct = (UA) => ['ios', 'macos'].includes(Bowser.getParser(UA).getOSName(true))
// export const isMobile       = (UA) => Bowser.getParser(UA).getPlatformType(true) === 'mobile'
// export const isTablet       = (UA) => Bowser.getParser(UA).getPlatformType(true) === 'tablet'
// export const isIOSDevice    = (UA) => Bowser.getParser(UA).getOSName(true) === 'ios'
// export const isIphoneSafari = (UA) => isTrue(Bowser.getParser(UA).satisfies({
//   mobile: {
//     safari: '>0',
//   },
// }))
// export const isEdge         = (UA) => Bowser.getParser(UA).getBrowserName() === 'Microsoft Edge'
// /**
//  * Detects IE from user-agent
//  * @example UA.isIE('Mozilla/5.0 (IE 11.0; Windows NT 6.3; WOW64; Trident/7.0; rv:11.0) like Gecko') // true
//  * @param {string} UA
//  */
// export const isIE           = UA => Bowser.getParser(UA).getBrowserName() === 'Internet Explorer'

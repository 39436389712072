import { pitchDetection } from 'models/PitchDetection'

export default {
  SET_VOLUME(state, value) {
    state.volume = value
  },

  SET_SUSTAIN(state, value) {
    state.sustain = value
  },

  SET_PIANO_KEY_SIZE_MULTIPLIER(state, value) {
    state.piano_key_size_multiplier = value
  },

  SET_MIC_SENSITIVITY(state, value) {
    state.micSensitivity = value
    pitchDetection.setMinSignal(value)
  }
}

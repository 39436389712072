export default {
  SET_IS_INTERACTIVE(state, value) {
    state.isInteractive = value
  },

  SET_IS_TIMER_ACTIVE(state, value) {
    state.isTimerActive = value
  },

  SET_TIMER_MS(state, value) {
    state.timerMS = value
  },

  SET_ANALYZE(state, value) {
    state.analyze = value
  }
}

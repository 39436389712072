import Vue from 'vue'

import 'framework/firebase-dir/init'

import 'src/registerServiceWorker'
import 'framework/environment/network-status'
import 'framework/environment/screen'
import 'framework/environment/auth'
import 'framework/environment/platform'

import 'framework/plugins/global-components'

import 'app-config/app-theme.css'
import 'framework/ui/style.css'

import vuetify from 'framework/plugins/vuetify'
import App     from './App'
import router  from 'src/router'
import store   from 'src/store'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default {
  async updateSettings({ commit }, settings) {
    for(const props of Object.values(settings)) {
      if(props.type === 'number') {
        props.value = parseFloat(props.value)
      }
      commit(props.mutation, props.value)
    }
  },

  setPianoKeySizeMultiplier({ commit }, value) {
    commit('SET_PIANO_KEY_SIZE_MULTIPLIER', value)
  },

  setMicSensitivity({ commit }, val) {
    commit('SET_MIC_SENSITIVITY', val)
  }
}

export default {
  setIsInteractive({ commit }, value) {
    commit('SET_IS_INTERACTIVE', value)
  },

  setTimerMs({ commit }, value) {
    commit('SET_TIMER_MS', value)
  },

  setIsTimerActive({ commit }, value) {
    commit('SET_IS_TIMER_ACTIVE', value)
  },

  setAnalyze({ commit }, value) {
    commit('SET_ANALYZE', value)
  }
}
import state     from 'framework/store/environment/environment.state'
import mutations from 'framework/store/environment/environment.mutations'
import actions   from 'framework/store/environment/environment.actions'
import getters   from 'framework/store/environment/environment.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

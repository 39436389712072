import { context } from 'tone'

/**
 * Currently using only auto correlation algorithm, as the YIN's results seem poor.
 */
export class PitchDetection {
  constructor(minSignal = 0.025) {
    this.minSignal  = minSignal
    this.sampleRate = context.sampleRate
  }

  setMinSignal(value) {
    if(value <= 100 && value > 0) {
      this.minSignal = value / 1000
    }
  }

  getMinSignal() {
    return this.minSignal * 1000
  }

  /**
   *
   * @param {Float32Array} buffer
   * @returns {{frequency: *}}
   */
  detect(buffer) {
    return this.autoCorrelate(buffer)
    // this.pitchConsensus([this.autoCorrelate, this.YINDetector], buffer)
  }

  autoCorrelate(buf) {
    let SIZE = buf.length
    let rms  = 0

    for(let i = 0; i < SIZE; i++) {
      const val = buf[i]
      rms += val * val
    }
    rms = Math.sqrt(rms / SIZE)

    if(rms < this.minSignal) {
      return -1
    }

    let r1 = 0, r2 = SIZE - 1, thres = 0.2
    for(let i = 0; i < SIZE / 2; i++) {
      if(Math.abs(buf[i]) < thres) {
        r1 = i
        break
      }
    }
    for(let i = 1; i < SIZE / 2; i++) {
      if(Math.abs(buf[SIZE - i]) < thres) {
        r2 = SIZE - i
        break
      }
    }
    buf  = buf.slice(r1, r2)
    SIZE = buf.length

    const c = new Array(SIZE).fill(0)
    for(let i = 0; i < SIZE; i++) {
      for(let j = 0; j < SIZE - i; j++) {
        c[i] = c[i] + buf[j] * buf[j + i]
      }
    }

    let d = 0
    while(c[d] > c[d + 1]) {
      d++
    }
    let maxVal = -1, maxPos = -1
    for(let i = d; i < SIZE; i++) {
      if(c[i] > maxVal) {
        maxVal = c[i]
        maxPos = i
      }
    }
    let T0 = maxPos

    const x1 = c[T0 - 1], x2 = c[T0], x3 = c[T0 + 1]
    const a  = (x1 + x3 - 2 * x2) / 2
    const b  = (x3 - x1) / 2
    if(a) {
      T0 = T0 - b / (2 * a)
    }

    return this.sampleRate / T0
  }

  /**
   * Doesn't give good results
   * @param chunk
   * @returns {number|*}
   */
  // YINDetector(float32AudioBuffer) {
  //   // Set buffer size to the highest power of two below the provided buffer's length.
  //   let bufferSize = 1
  //   while(bufferSize < float32AudioBuffer.length) {
  //     bufferSize *= 2
  //   }
  //   bufferSize /= 2
  //
  //   // Set up the yinBuffer as described in step one of the YIN paper.
  //   const yinBufferLength = bufferSize / 2
  //   const yinBuffer       = new Float32Array(yinBufferLength)
  //
  //   let probability = 0,
  //       tau
  //
  //   // Compute the difference function as described in step 2 of the YIN paper.
  //   for(let t = 0; t < yinBufferLength; t++) {
  //     yinBuffer[t] = 0
  //   }
  //   for(let t = 1; t < yinBufferLength; t++) {
  //     for(let i = 0; i < yinBufferLength; i++) {
  //       const delta = float32AudioBuffer[i] - float32AudioBuffer[i + t]
  //       yinBuffer[t] += delta * delta
  //     }
  //   }
  //
  //   // Compute the cumulative mean normalized difference as described in step 3 of the paper.
  //   yinBuffer[0]   = 1
  //   yinBuffer[1]   = 1
  //   let runningSum = 0
  //   for(let t = 1; t < yinBufferLength; t++) {
  //     runningSum += yinBuffer[t]
  //     yinBuffer[t] *= t / runningSum
  //   }
  //
  //   // Compute the absolute threshold as described in step 4 of the paper.
  //   // Since the first two positions in the array are 1,
  //   // we can start at the third position.
  //   for(tau = 2; tau < yinBufferLength; tau++) {
  //     if(yinBuffer[tau] < this.minSignal * 10) {
  //       while(tau + 1 < yinBufferLength && yinBuffer[tau + 1] < yinBuffer[tau]) {
  //         tau++
  //       }
  //       // found tau, exit loop and return
  //       // store the probability
  //       // From the YIN paper: The threshold determines the list of
  //       // candidates admitted to the set, and can be interpreted as the
  //       // proportion of aperiodic power tolerated
  //       // within a periodic signal.
  //       //
  //       // Since we want the periodicity and and not aperiodicity:
  //       // periodicity = 1 - aperiodicity
  //       probability = 1 - yinBuffer[tau]
  //       break
  //     }
  //   }
  //
  //   // if no pitch found, return null.
  //   if(tau === yinBufferLength || yinBuffer[tau] >= this.minSignal * 10) {
  //     return null
  //   }
  //
  //   // If probability too low, return -1.
  //   if(probability < this.minSignal * 10) {
  //     return null
  //   }
  //
  //   /**
  //    * Implements step 5 of the AUBIO_YIN paper. It refines the estimated tau
  //    * value using parabolic interpolation. This is needed to detect higher
  //    * frequencies more precisely. See http://fizyka.umk.pl/nrbook/c10-2.pdf and
  //    * for more background
  //    * http://fedc.wiwi.hu-berlin.de/xplore/tutorials/xegbohtmlnode62.html
  //    */
  //   let betterTau, x0, x2
  //   if(tau < 1) {
  //     x0 = tau
  //   } else {
  //     x0 = tau - 1
  //   }
  //   if(tau + 1 < yinBufferLength) {
  //     x2 = tau + 1
  //   } else {
  //     x2 = tau
  //   }
  //   if(x0 === tau) {
  //     if(yinBuffer[tau] <= yinBuffer[x2]) {
  //       betterTau = tau
  //     } else {
  //       betterTau = x2
  //     }
  //   } else if(x2 === tau) {
  //     if(yinBuffer[tau] <= yinBuffer[x0]) {
  //       betterTau = tau
  //     } else {
  //       betterTau = x0
  //     }
  //   } else {
  //     const s0  = yinBuffer[x0]
  //     const s1  = yinBuffer[tau]
  //     const s2  = yinBuffer[x2]
  //     betterTau = tau + (s2 - s0) / (2 * (2 * s1 - s2 - s0))
  //   }
  //
  //   return this.sampleRate / betterTau
  // }

  //Not needed since only one algorithm is used.
  /**
   *
   * @param chunk
   * @returns {number|*}
   */
  // pitchConsensus(chunk) {
  //   const pitches = [this.autoCorrelate(chunk), this.YINDetector(chunk)]
  //     .filter((value) => value !== null)
  //     .sort((a, b) => a - b)
  //
  //   // In the case of one pitch, return it.
  //   if(pitches.length === 1) {
  //     return pitches[0]
  //
  //     // In the case of two pitches, return the geometric mean if they
  //     // are close to each other, and the lower pitch otherwise.
  //   } else if(pitches.length === 2) {
  //     const [first, second] = pitches
  //     return first * 2 > second ? Math.sqrt(first * second) : first
  //
  //     // In the case of three or more pitches, filter away the extremes
  //     // if they are very extreme, then take the geometric mean.
  //   } else {
  //     const first        = pitches[0]
  //     const second       = pitches[1]
  //     const secondToLast = pitches[pitches.length - 2]
  //     const last         = pitches[pitches.length - 1]
  //
  //     const filtered1 = first * 2 > second ? pitches : pitches.slice(1)
  //     const filtered2 = secondToLast * 2 > last ? filtered1 : filtered1.slice(0, -1)
  //     return Math.pow(
  //       filtered2.reduce((t, p) => t * p, 1),
  //       1 / filtered2.length,
  //     )
  //   }
  // }
}

const pitchDetection = new PitchDetection()

export { pitchDetection }

import firebase                         from 'firebase/app'
import { isNil }                             from 'framework/utilities/lodashFunctions'
import { createNewUserFromFirebaseAuthUser } from 'framework/helpers/CreateFirebaseAuthUser'
import DB                                          from 'framework/models/GenericDB'
import { EXERCISE_DB_NAME, SAVED_RESULTS_DB_NAME } from 'src/misc/app-constants'

export default {
  setupUserData({ commit }, { user: { id } }) {
    if(id) {
      commit('SET_DB', { id, dbName: EXERCISE_DB_NAME })
      commit('SET_DB', { id, dbName: SAVED_RESULTS_DB_NAME })
    }
  },

  /**
   * Callback fired when user login
   */
  async login({ commit, dispatch }, firebaseAuthUser) {
    if( !firebaseAuthUser) {
      try {
        await firebase.auth().signInAnonymously()
        firebaseAuthUser = firebase.auth().currentUser
      } catch (e) {
        console.log(e)
      }
    }
    const userFromFirebase = await new DB('users').read(firebaseAuthUser.uid)

    const user = isNil(userFromFirebase)
                 ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
                 : userFromFirebase

    user.email         = firebaseAuthUser.email
    user.emailVerified = firebaseAuthUser.emailVerified

    commit('SET_USER', user)
    dispatch('setupUserData', { user })
  },

  /**
   * Callback fired when user logout
   */
  async logout({ commit }) {
    commit('SET_USER', null)
  },

  verifyAuthenticated({ getters, dispatch }) {
    if( !getters.getIsLoggedIn || !getters.getIsUserVerified) {
      const body = 'You must be logged in to download midi files.'
      dispatch('view/setDialog', { active: true, title: 'Error', body }, { root: true })
      return false
    }
    return true
  },
}

import { isNil } from 'framework/utilities/lodashFunctions'

export default {
  /**
   * Trigger service worker skipWating so the new service worker can take over.
   * This will also trigger a window refresh (see /src/registerServiceWorker.js)
   */
  serviceWorkerSkipWaiting({ state, commit, dispatch }) {
    if(isNil(state.SWRegistrationForNewContent)) {
      return
    }

    dispatch('view/setIsLoadingPage', true, { root: true })
    commit('setRefreshingApp', true)
    state.SWRegistrationForNewContent.waiting.postMessage('skipWaiting')
  },
}

export default {
  dropdown:    {
    component: 'app-icon',
    props:     {
      icon: 'expand-more',
    },
  },
  help:        {
    name:      'Help',
    component: 'app-icon',
    props:     {
      icon: 'help',
    },
  },
  prev:        {
    component: 'app-icon',
    props:     {
      icon: 'arrow-left',
    },
  },
  next:        {
    component: 'app-icon',
    props:     {
      icon: 'arrow-right',
    },
  },
  checkboxOn:  {
    component: 'app-icon',
    props:     {
      icon: 'checkbox-on',
    },
  },
  checkboxOff: {
    component: 'app-icon',
    props:     {
      icon: 'checkbox-off',
    },
  },
  expand:      {
    component: 'app-icon',
    props:     {
      icon: 'expand-more',
    },
  },
  radioOn:     {
    component: 'app-icon',
    props:     {
      icon: 'radio-on',
    },
  },
  radioOff:    {
    component: 'app-icon',
    props:     {
      icon: 'radio-off',
    },
  },
  clear:       {
    component: 'app-icon',
    props:     {
      icon: 'clear',
      size: 18,
    },
  },
  aspectRatio: {
    name:      'AspectRatio',
    component: 'app-icon',
    props:     {
      icon: 'aspect-ratio',
    },
  },
  playArrow:   {
    name:      'PlayArrow',
    component: 'app-icon',
    props:     {
      icon: 'play-arrow',
    },
  },
  edit:        {
    name:      'Edit',
    component: 'app-icon',
    props:     {
      icon: 'edit',
    },
  },
  email:       {
    name:      'Email',
    component: 'app-icon',
    props:     {
      icon: 'email',
    },
  },
  place:       {
    name:      'Place',
    component: 'app-icon',
    props:     {
      icon: 'place',
    },
  },
  thumsUp:     {
    name:      'thumbs-up',
    component: 'app-icon',
    props:     {
      icon: 'thumbs-up',
    },
  },
  volumeUp: {
    name:      'volume-up',
    component: 'app-icon',
    props:     {
      icon: 'volume-up',
    },
  },
  widthZoom: {
    name:      'zoom-out-horizontal',
    component: 'app-icon',
    props:     {
      icon: 'zoom-out-horizontal',
    },
  },
  heightZoom: {
    name:      'zoom-out-vertical',
    component: 'app-icon',
    props:     {
      icon: 'zoom-out-vertical',
    },
  },
}
export default {
  SET_IS_DOWNLOADING(state, value) {
    state.is_downloading = value
  },

  SET_SNACKBAR(state, payload) {
    state.snackbar = { ...state.snackbar, ...payload }
  },

  SET_DIALOG(state, payload) {
    state.dialog = { ...state.dialog, ...payload }
  },

  SET_IS_LOADING_PAGE(state, payload) {
    state.is_loading_page = payload
  },
}

import DB         from 'framework/models/GenericDB'
import { APP_ID } from 'misc/app-constants'

export default {
  SET_DB(state, { id, dbName }) {
    state[dbName] = new DB(`${ APP_ID }/${ id }/${ dbName }`)
  },

  SET_USER(state, value) {
    state.user = value
  }
}

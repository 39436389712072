import GenericDB from 'framework/models/GenericDB'

/**
 * Create new user from firebase auth user infos
 */
  // eslint-disable-next-line
export const createNewUserFromFirebaseAuthUser = async firebaseAuthUser => {
    const anonymousUser                    = {
      displayName: null,
      photoURL:    null,
      email:       null,
    }
    const providerData                     = firebaseAuthUser.providerData[0] || anonymousUser
    const userDb                           = new GenericDB('users')
    const { displayName, photoURL, email } = providerData
    const user                             = {
      displayName,
      photoURL,
      email,
    }
    return userDb.create(user, firebaseAuthUser.uid)
  }

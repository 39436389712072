import firebase  from 'firebase/app'
import { isNil } from 'framework/utilities/lodashFunctions'
import appConfig from 'app-config/app.json'

import store from 'src/store'

firebase.auth().onAuthStateChanged(user => {
  const actionToDispatch = isNil(user) ? 'logout' : 'login'
  store.dispatch(`user/${actionToDispatch}`, user)

  if (process.env.NODE_ENV === 'production' && user) {
    firebase.analytics().setUserId(user.uid)
    firebase.analytics().setUserProperties({
      account_type: 'Basic', // can help you to define audiences
    })
  }
})

firebase.auth().onIdTokenChanged(user => {
  const actionToDispatch = isNil(user) ? 'logout' : 'login'
  store.dispatch(`user/${actionToDispatch}`, user)
})

export async function handleVerifyEmail(actionCode) {
  try {
    await firebase.auth().applyActionCode(actionCode)
    await firebase.auth().currentUser.reload()
    await firebase.auth().currentUser.getIdToken(true)
    return 'SUCCESS, You have been verified!'
  } catch (e) {
    return 'Error. Please try again later.'
  }
}

export async function handleResetPassword(actionCode) {
  // @todo
  return [actionCode]
}

export async function handleRecoverEmail(actionCode) {
  // @todo
  return actionCode
}

export async function checkVerified() {
  await firebase.auth().currentUser.getIdToken(true)
  await firebase.auth().currentUser.reload()
}

export function sendVerificationEmail() {
  const actionCodeSettings = {
    url: appConfig.url,
  }
  const user               = firebase.auth().currentUser
  user.sendEmailVerification(actionCodeSettings).then(function () {
    store.dispatch('view/setSnackbar', { text: `An email has been sent to ${user.email}`, timeout: 4000, active: true })
  }).catch((error) => {
    console.log(error)
    store.dispatch('view/setSnackbar', { text: 'Error: Please try again later.', timeout: 4000, active: true })
  })
}

export default [
  {
    path:      '*',
    name:      'Home',
    component: () => import(/* webpackChunkName: "home", webpackPreload: true */ 'framework/ui/views/Home'),
    meta:      {
      authNotRequired: true,
    },
  },
  {
    path:      '/auth',
    name:      'Auth',
    component: () => import(/* webpackChunkName: "auth" */ 'framework/ui/views/Auth'),
    meta:      {
      authNotRequired: true,
    },
  },
  {
    path:      '/about',
    name:      'About',
    component: () => import(/* webpackChunkName: "info" */ 'framework/ui/views/About'),
    meta:      {
      authNotRequired: true,
    },
  },
  {
    path:      '/login',
    name:      'Login',
    component: () => import(/* webpackChunkName: "auth" */ 'framework/ui/views/Login'),
    meta:      {
      authNotRequired: true,
    },
  },
  {
    path:      '/terms-of-use',
    name:      'Terms Of Use',
    component: () => import(/* webpackChunkName: "info" */ 'framework/ui/views/TermsOfUse'),
    meta:      {
      authNotRequired: true,
    },
  },
  {
    path:      '/privacy-policy',
    name:      'Privacy Policy',
    component: () => import(/* webpackChunkName: "info" */ 'framework/ui/views/PrivacyPolicy'),
    meta:      {
      authNotRequired: true,
    },
  },
]
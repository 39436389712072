import store from 'src/store'

store.commit('environment/setNetworkOnline', navigator.onLine)

window.addEventListener('online', () =>
  store.commit('environment/setNetworkOnline', true),
)

window.addEventListener('offline', () =>
  store.commit('environment/setNetworkOnline', false),
)
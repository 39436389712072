import firebase  from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import appConfig from 'app-config/app.json'

firebase.initializeApp(appConfig.firebase)

// google analytics
window.dataLayer = window.dataLayer || []

// eslint-disable-next-line no-undef
function gtag() {dataLayer.push(arguments)}

if(process.env.NODE_ENV === 'production') {
  firebase.analytics()

  gtag('js', new Date())
  gtag('config', 'UA-129261774-2')

  const title      = location.pathname.split('/')[1] || 'Home'
  const page_title = `App | ${appConfig.name} | ${title}`

  firebase.analytics().setCurrentScreen(page_title)
  firebase.analytics().logEvent('page_view', {
    'page_path': location.pathname,
    page_title,
  })
}

export const APP_ID = 'vocal-coach'
export const RANGE_END = 'range-end'

export const EXERCISE_DB_NAME               = 'saved-exercises'
export const SAVED_RESULTS_DB_NAME               = 'saved-results'

export const MODULES_TO_PERSIST             = ['context', 'settings']
export const MUTATION_TYPES_TO_TRIGGER_SAVE = [
  'context/SET_PITCH_CLASS',
  'context/SET_OCTAVE',
  'settings/SET_MIC_SENSITIVITY',
  'settings/SET_SUSTAIN',
]
import Vue           from 'vue'
import Router        from 'vue-router'
import Head          from 'vue-head'
import firebase      from 'firebase/app'
import 'firebase/analytics'
import { isNil }     from 'framework/utilities/lodashFunctions'
import store         from 'src/store'
import appConfig     from 'app-config/app'
import defaultRoutes from 'framework/plugins/router'

Vue.use(Router)

Vue.use(Head, {
  complement: appConfig.name
})

let initialized = false

const preRouteHook = {
  beforeEnter: async (to, from, next) => {
    if( !initialized) {
      (await import('src/helpers/register-vuex-modules')).setupVuexModules()
      await (await import('src/helpers/setup-app')).setupApp()
      // await import('framework/misc/helpers/push-notifications')
      initialized = true
    }

    if(process.env.NODE_ENV === 'production') {
      const title      = to.name.toLowerCase() || 'home'
      const page_title = `App | ${ appConfig.name } | ${ title }`

      firebase.analytics().setCurrentScreen(title)
      firebase.analytics().logEvent('page_view', {
        'page_path': to.path,
        page_title
      })
    }

    store.dispatch('transport/resetTransport')
    next()
  }
}

const routes = [
  ...defaultRoutes,
  {
    path:      '/pitch-practice',
    name:      'Pitch Practice',
    component: () => import('views/PitchPractice'),
    meta:      {
      authNotRequired: true
    },
    ...preRouteHook
  },
  {
    path:      '/exercises',
    name:      'Exercises',
    component: () => import('views/Exercises'),
    meta:      {
      authNotRequired: true
    },
    ...preRouteHook
  },
  {
    path:      '/exercise',
    name:      'Exercise',
    component: () => import('views/Exercise'),
    meta:      {
      authNotRequired: true
    },
    ...preRouteHook
  },
  {
    path:      '/pitch-detector',
    name:      'Pitch Detector',
    component: () => import('views/Detector'),
    meta:      {
      authNotRequired: true
    },
    ...preRouteHook
  },
  {
    path:      '/metronome',
    name:      'Metronome',
    component: () => import('views/Metronome'),
    meta:      {
      authNotRequired: true
    },
    ...preRouteHook
  },
  {
    path:      '/profile',
    name:      'Profile-Page',
    component: () => import(/* webpackChunkName: "auth" */ 'views/Profile'),
    meta:      {
      authNotRequired: false
    },
    ...preRouteHook
  },
  // {
  //   path:      '/g',
  //   name:      'Guitar',
  //   component: () => import(/* webpackChunkName: "auth" */ 'framework/ui/sections/GuitarVisualization'),
  //   meta:      {
  //     authNotRequired: true
  //   },
  //   ...preRouteHook
  // }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * Handle user redirections
 */
router.beforeEach(async (to, from, next) => {
  store.dispatch('view/setIsLoadingPage', true)
  if(
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.user.user)
  ) {
    const path = store.state.user.user === null ? '/login' : '/check-login'
    return next(`${ path }?redirectUrl=${ to.path }`)
  } else {
    next()
  }
})

router.afterEach(() => {
  store.dispatch('view/setIsLoadingPage', false)
})

export default router

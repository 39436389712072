export default {
  setNetworkOnline(state, value) {
    state.network_online = value
  },

  setSWRegistrationForNewContent(state, value) {
    state.SWRegistrationForNewContent = value
  },

  setRefreshingApp(state, value) {
    state.refreshingApp = value
  },

  setWidth(state, value) {
    state.width = value
  },

  setHeight(state, value) {
    state.height = value
  }
}
